import React from "react";
import { PropTypes } from "prop-types";
import DetailItem from "../../components/DetailItem/";
import { WorkerStyles as styles } from "../../styles";
import { TOOLTIP_MAPPING } from "./constants";
import { ActionWrapper, Tooltip } from "../WorkerContainer/actions/styled";

const WorkerDetails = ({ details, showSSNTrace }) => {
  let worker_details = details.filter(detail => !!detail.value);
  if (!showSSNTrace)
    worker_details = worker_details.filter(detail => detail.label !== "SSN");
  return (
    <div>
      {worker_details.map((detail, index) => {
        const detail_tooltip = TOOLTIP_MAPPING[detail.label] || "";
        if (detail_tooltip)
          return (
            <ActionWrapper>
              <Tooltip
                className="tooltip-menu-item"
                position={"top_left"}
                style={{ width: "280px" }}
              >
                {detail_tooltip}
              </Tooltip>
              <div
                style={
                  index + 1 === worker_details.length ? {} : styles.divider
                }
                key={index}
              >
                <DetailItem
                  detail={detail}
                  containerStyle={styles.detailContainer}
                  labelStyle={styles.detailLabel}
                  descriptionStyle={styles.detailDescription}
                />
              </div>
            </ActionWrapper>
          );
        return (
          <div
            style={index + 1 === worker_details.length ? {} : styles.divider}
            key={index}
          >
            <DetailItem
              detail={detail}
              containerStyle={styles.detailContainer}
              labelStyle={styles.detailLabel}
              descriptionStyle={styles.detailDescription}
            />
          </div>
        );
      })}
    </div>
  );
};
WorkerDetails.propTypes = {
  details: PropTypes.array,
  showSSNTrace: PropTypes.bool
};
WorkerDetails.defaultProps = {
  details: [],
  showSSNTrace: true
};

export default WorkerDetails;
