import { observable, action, computed, decorate } from "mobx";
import React from "react";
import _, { isObject, isArray } from "lodash";
import moment from "moment";
import env from "@mars/heroku-js-runtime-env";
import Run from "material-ui/svg-icons/maps/directions-run";
import Balance from "material-ui/svg-icons/action/account-balance";
import Hammer from "material-ui/svg-icons/action/gavel";
import Person from "material-ui/svg-icons/social/person";
import MoneyOff from "material-ui/svg-icons/editor/money-off";
import { validators, formatPhone, getStatus } from "turn-shared";

import { geocode } from "../services/google-maps";
import { turnAPI } from "../services/";
import renderBadge from "../utils/render-badge";
import { maskName, translateStatus } from "turn-shared";
import {
  getWatchlistData,
  getCriminalData,
  getPropertyData,
  getVehicleData
} from "./worker-info-helper";
import renderStatusLabel from "../utils/render-status-label";
import {
  getFilteredRecords,
  getUniqueByFields,
  nationalCallback,
  soCallback,
  watchlistCallback
} from "./helpers/current-worker";
import { reformatDate } from "../utils/momentParse";
import {
  ATTENTION,
  CLEAR,
  FULFILLED,
  MVR_MONITORING_ONE_OFF_CHECK,
  ON_DEMAND_CRIM,
  ON_DEMAND_MVR,
  PENDING,
  PROCESSING,
  READY,
  WITHDRAWN
} from "../constants";
import { PROCESSING_ON_DEMAND_CRIM_STATUS } from "./constants";

const {
  validateCheck,
  validatePhoneVerification,
  validateMVRCheck,
  disable_check
} = validators;

class CurrentWorker {
  // Loading
  loading = false;
  loadingRecheck = false;

  readOnly = false;

  //Tabs
  selectedTabId = null;

  // Criminal Records type
  criminalRecordsType = "national";

  // Worker Details
  driverLicense = "";
  driverLicenseState = "";
  phone = "";
  phoneVerification = "";
  email = "";
  dateOfBirth = "";
  userProvidedDOB = "";
  signUpDate = "";
  referenceId = "";
  zipcode = "";
  packageName = "";
  report_parameters = {};
  turnId = "";
  hasCarInsurance = "";
  isPendingReview = false;
  isPendingMVRReview = false;
  isProcessingMVR = false;
  isPendingAgeReview = false;
  hasMVRMonitoringOneOffChecks = false;
  worker = {};
  checkDate = "";

  //Rejected reasons
  rejectionDetails = {};
  notes = null;

  // Seen Dates
  detailsFirstSeen = "";
  detailsLastSeen = "";
  ssnFirstSeen = "";
  ssnLastSeen = "";
  addressFirstSeen = "";
  addressLastSeen = "";

  // SSN Trace
  ssn = "";
  ssnStatus = "";
  ssnVerified = "";
  ssnMatch = "";
  ssnIssueDate = "";
  ssnIssueState = "";
  deceasedDate = "";
  is_deceased = false;
  dobStatus = "";
  is_random_ssn = false;
  geocodeData = null;
  showSSNTrace = true;

  // Assets
  vehicles = [];
  properties = [];

  // Checks
  ssnChecks = {};
  sexOffenders = null;
  vehicleChecks = null;
  criminalRecords = null;
  countyCriminalRecords = null;
  federalRecords = null;
  oneOffChecks = null;
  canadianReport = null;
  bankruptcies = null;
  judgments = null;
  liens = null;
  watchLists = null;
  mvr = {};
  showCounty = false; // Remove when we finish migrating to ETA component
  checksToRun = [];
  rechecks = [];

  // Drug Test
  doDrugTesting = false;
  drugTestingResults = null;

  // Addresses
  addresses = [];
  currentAddress = {};
  showAllAddresses = false;

  // Location
  location = {};

  // Worker Sidebar
  firstName = "";
  lastName = "";
  status = "";
  onBoardingStatus = "";
  plainOnBoardingStatus = "";
  checkStatus = "";

  // Enrollment Info
  criminalMonitoring = {};
  drivingMonitoring = {};

  // Worker ID
  id = "";

  // Manual Mode
  manual = false;
  pdfURL = "";

  currentWorker = false;
  setRequestCharges = false;

  //
  mvr_processing = false;
  ssn_review = false;
  mvr_review = false;
  age_review = false;
  isDdppar = false;

  //ETA Dates
  initiated_date = null;
  eta = null;

  get fullName() {
    if (env().REACT_APP_ENVIRONMENT === "production")
      return `${this.firstName} ${this.lastName}`;
    return `${this.firstName} ${maskName(this.lastName)}`;
  }

  get currentState() {
    if (_.isEmpty(this.currentAddress)) return "";
    return this.currentAddress.state;
  }

  get providedState() {
    if (_.isEmpty(this.geocodeData)) return "";
    return this.geocodeData.state;
  }

  get expiredLicenses() {
    if (typeof this.mvr === "string") return [];
    if (!this.mvr.licenses) return [];
    return this.mvr.licenses.filter(license =>
      moment(license.exp_date).isBefore(moment())
    );
  }

  get invalidLicenses() {
    if (typeof this.mvr === "string") return [];
    if (!this.mvr.licenses) return [];
    const invalid = this.mvr.licenses.filter(license => !license.is_valid);
    return invalid;
  }

  get isMVRClear() {
    if (typeof this.mvr === "string") return true;
    if (this.mvr.status === "clear") return true;
    if (this.mvr.status === "consider") return false;
    return true;
  }

  get driverLicenseStatus() {
    return this.driverLicense || this.driverLicenseState ? "" : "none";
  }

  get mortality() {
    return this.deceasedDate ? "deceased" : "";
  }

  get countyCriminalData() {
    if (this.canadianReport) {
      return _.get(this.canadianReport, "report_data.criminal_check", null);
    } else {
      const records = validateCheck(this.countyCriminalRecords);
      if (!records) return null;
      return records;
    }
  }

  get federalCriminalData() {
    const records = this.federalRecords;
    if (!records) return null;
    return records;
  }

  get candidate_provided_ssn() {
    return this.ssnChecks.candidate_provided_ssn;
  }

  get candidate_has_car_insurance() {
    return this.hasCarInsurance === ""
      ? null
      : this.hasCarInsurance
      ? "Yes"
      : "No";
  }

  get details() {
    const _details = [
      {
        label: "Status",
        value: getStatus(this.onBoardingStatus),
        renderer: renderBadge
      },
      { label: "Started", value: this.initiated_date },
      { label: "ETA", value: this.eta },
      { label: "Turn ID", value: this.turnId },
      { label: "Legal Name", value: this.fullName },
      { label: "Phone", value: formatPhone(this.phone) },
      { label: "Email", value: this.email },
      { label: "SSN", value: this.candidate_provided_ssn || this.ssn },
      {
        label: "DOB",
        value: reformatDate(this.userProvidedDOB || this.dateOfBirth)
      },
      { label: "Package", value: this.packageName }
    ];
    const details =
      env().REACT_APP_ENABLE_OLD_CANDIDATE_INFORMATION === "true"
        ? [
            ..._details,
            {
              label: "Check status",
              value: this.checkStatus,
              renderer: renderStatusLabel
            },
            {
              label: "Onboarding",
              value: this.onBoardingStatus,
              renderer: renderBadge
            },
            {
              label: "Has car insurance",
              value: this.candidate_has_car_insurance
            },
            { label: "Sign Up Date", value: this.signUpDate }
          ]
        : this.referenceId &&
          env().REACT_APP_ENABLE_OLD_CANDIDATE_INFORMATION === "true"
        ? [...details, { label: "Reference Id", value: this.referenceId }]
        : _details;
    return details;
  }

  get isRejected() {
    return (
      this.onBoardingStatus === "rejected" ||
      this.onBoardingStatus === "first_notice" ||
      this.onBoardingStatus === "second_notice"
    );
  }

  setZipcode = async zipcode => {
    if (typeof zipcode !== "string") return;
    this.zipcode = zipcode;
  };

  setGeocodeData = async () => {
    if (this.zipcode.length !== 5) {
      this.geocodeData = null;
      return;
    }
    const resp = await turnAPI.getZipCode(this.zipcode);
    if (!resp.ok || resp.status !== 200) return;
    this.geocodeData = resp.data;
  };

  setChecksToRun = checksToRun => {
    if (!Array.isArray(checksToRun)) return;
    this.checksToRun = checksToRun;
  };

  setRechecks = rechecks => {
    if (!Array.isArray(rechecks)) return;
    this.rechecks = rechecks;
  };

  setCheckDate = checkDate => {
    if (!_.isString(checkDate)) return;
    this.checkDate = checkDate;
  };

  shouldShowCheck = check => {
    if (_.isString(check)) return this.checksToRun.includes(check);
    if (Array.isArray(check))
      return this.checksToRun.some(value => check.includes(value));
    return false;
  };

  get stateAbbr() {
    if (!this.geocodeData) return null;
    return this.geocodeData.state;
  }

  get criminalData() {
    return getCriminalData(validateCheck(this.criminalRecords));
  }

  get watchlistData() {
    return getWatchlistData(validateCheck(this.watchLists));
  }

  get propertyData() {
    return getPropertyData(validateCheck(this.properties));
  }

  get vehicleData() {
    return getVehicleData(validateCheck(this.vehicles));
  }

  get preAdverseJurisdiction() {
    const currentCity =
      this.addresses && this.addresses[0] && this.addresses[0].city
        ? this.addresses[0].city.toLowerCase()
        : "";

    return {
      value: this.setRequestCharges,
      jurisdiction: this.setRequestCharges ? currentCity : null
    };
  }

  get checks() {
    let checks = [
      {
        icon: <Person />,
        label: "Sex Offender Check",
        value: validateCheck(this.sexOffenders),
        masterKey: "state"
      },
      {
        icon: <Run />,
        label: "Criminal Records",
        value:
          this.criminalRecordsType === "national" ? this.criminalData : null,
        masterKey: "offense_description_1",
        exclude: [
          "worker_info",
          "a_k_as",
          "sentence",
          "other_dates_of_birth",
          "other_addresses",
          "birth_address"
        ],
        secondary: "worker_info"
      },
      {
        icon: <Balance />,
        label: "Bankruptcies",
        value: validateCheck(this.bankruptcies),
        masterKey: "file_date"
      },
      {
        icon: <Hammer />,
        label: "Judgements",
        value: validateCheck(this.judgments),
        masterKey: "FilingDate"
      },
      {
        icon: <MoneyOff />,
        label: "Liens",
        value: validateCheck(this.liens),
        masterKey: "date"
      }
    ];
    // Remove when we finish migrating to ETA component
    if (!this.showCounty) {
      checks = [
        ...checks,
        {
          icon: <Run />,
          label: "Criminal Records",
          value:
            this.criminalRecordsType === "county"
              ? this.countyCriminalData
              : null,
          masterKey: "offense_description_1",
          groupByProperty: "county",
          exclude: [
            "worker_info",
            "a_k_as",
            "sentence",
            "other_dates_of_birth",
            "other_addresses",
            "birth_address"
          ],
          secondary: "worker_info",
          countItems: this.countyCriminalData
            ? this.countyCriminalData.filter(
                record => record.value || record.status === "processing"
              )
            : []
        }
      ];
    }
    return checks;
  }

  setLoading = loading => {
    if (typeof loading !== "boolean") return;
    this.loading = loading;
  };

  setLoadingRecheck = loadingRecheck => {
    if (typeof loadingRecheck !== "boolean") return;
    this.loadingRecheck = loadingRecheck;
  };

  setReadOnly = readOnly => {
    if (typeof readOnly !== "boolean") return;
    this.readOnly = readOnly;
  };

  setSelectedTabId = id => {
    return (this.selectedTabId = !_.isNumber(id) ? null : id);
  };

  setManual = manual => {
    if (typeof manual !== "boolean") return;
    this.manual = manual;
  };

  setPDFURL = pdfURL => {
    if (typeof pdfURL !== "string") return;
    this.pdfURL = pdfURL;
  };

  setCriminalRecordsType = type => {
    if (typeof type !== "string") return;
    this.criminalRecordsType = type;
  };

  setShowSSNTrace = show => {
    if (typeof show !== "boolean") return;
    this.showSSNTrace = show;
  };

  setShowAllAddresses = show => {
    if (typeof show !== "boolean") return;
    this.showAllAddresses = show;
  };

  setHasCarInsurance = bool => {
    if (typeof bool !== "boolean") return;
    this.hasCarInsurance = bool;
  };

  setAssets = assets => {
    if (!assets) return;
    const currentVehicles = assets.current_vehicles || [];
    const pastVehicles = assets.past_vehicles || [];
    const currentProperties = assets.current_properties || [];
    const pastProperties = assets.past_properties || [];
    this.vehicles = _.orderBy(
      [...currentVehicles, ...pastVehicles],
      "year",
      "desc"
    );
    this.properties = [...currentProperties, ...pastProperties];
  };

  getOnDemandCrimCounty = (custom_inputs = {}) => {
    if (_.isEmpty(custom_inputs)) return;

    const {
      ON_DEMAND_CRIM_COUNTY: county = "",
      ON_DEMAND_CRIM_STATE: state = ""
    } = custom_inputs;
    return `${_.upperCase(state)}-${_.upperCase(county)}`;
  };

  getOnDemandCrimHasHits = (state = "", resolution = "") => {
    // This aberration is due to the validations for icon and color expecting an array and checking its length
    if (
      state === ATTENTION ||
      state === PENDING ||
      state === WITHDRAWN ||
      resolution === _.upperCase(PENDING)
    ) {
      return ["1"];
    }
    return [];
  };

  setOnDemandCountyCriminalRecords = async (report = {}) => {
    if (_.isEmpty(report)) return;

    const { custom_inputs, name, report_url, resolution, state } = report;

    const _resolution = _.upperCase(resolution);

    const criminalRecord = validateCheck([
      {
        county: this.getOnDemandCrimCounty(custom_inputs),
        has_hits: this.getOnDemandCrimHasHits(state, _resolution),
        name,
        report_url: report_url,
        resolution: _resolution,
        status:
          state === FULFILLED && _resolution === _.upperCase(CLEAR)
            ? READY
            : PROCESSING_ON_DEMAND_CRIM_STATUS.includes(state)
            ? PROCESSING
            : state
      }
    ]);

    this.countyCriminalRecords = _.isEmpty(this.countyCriminalRecords)
      ? [...criminalRecord]
      : [...this.countyCriminalRecords, ...criminalRecord];
  };

  setOneOffChecks = (reports = []) => {
    if (_.isEmpty(reports)) return;

    const oneOffChecks = [];

    reports.forEach(report => {
      report.name === ON_DEMAND_CRIM
        ? this.setOnDemandCountyCriminalRecords(report)
        : oneOffChecks.push(report);
    });

    if (!_.isEmpty(oneOffChecks)) this.oneOffChecks = oneOffChecks;
  };

  setChecks = worker => {
    if (!worker) return;
    if (_.isEmpty(this.worker)) {
      this.worker = worker;
    }
    const mvr_checks = worker.profile.checks.mvr;
    this.countyCriminalRecords = disable_check(
      env().REACT_APP_DISABLE_COUNTY_CRIMINAL
    )
      ? null
      : validateCheck(worker.criminalRecords);
    this.federalRecords = disable_check(env().REACT_APP_DISABLE_FEDERAL_CHECK)
      ? null
      : worker.profile.checks && worker.profile.checks.federal;
    this.setSSNChecks(worker.checks);
    if (!worker.profile || !worker.profile.checks) return;
    this.sexOffenders = disable_check(env().REACT_APP_DISABLE_SEX_OFFENDER)
      ? null
      : getUniqueByFields(
          validateCheck(worker.profile.checks.sex_offender),
          soCallback
        );
    this.vehicleChecks = validateCheck(worker.profile.checks.vehicles);
    this.criminalRecords = disable_check(
      env().REACT_APP_DISABLE_NATIONAL_CRIMINAL
    )
      ? null
      : getUniqueByFields(
          validateCheck(worker.profile.checks.criminal),
          nationalCallback
        );
    this.setOneOffChecks(worker.profile.checks.one_off_check_reports);
    this.canadianReport =
      disable_check(env().REACT_APP_DISABLE_CANADIAN_RECORD) ||
      !worker.profile.checks.rcmp_report
        ? null
        : worker.profile.checks.rcmp_report;
    this.bankruptcies = disable_check(env().REACT_APP_DISABLE_BANKRUPTCIES)
      ? null
      : validateCheck(worker.profile.checks.bankruptcies);
    this.judgments = disable_check(env().REACT_APP_DISABLE_JUDGEMENTS)
      ? null
      : validateCheck(worker.profile.checks.judgements);
    this.liens = disable_check(env().REACT_APP_DISABLE_LIENS)
      ? null
      : validateCheck(worker.profile.checks.liens);
    this.watchLists = disable_check(env().REACT_APP_DISABLE_WATCHLIST)
      ? null
      : getUniqueByFields(
          validateCheck(worker.profile.checks.watchlist),
          watchlistCallback
        );
    if (worker.profile.do_drug_test) {
      this.doDrugTesting = true;
      this.drugTestingResults = worker.drug_testing;
    }
    if (mvr_checks !== ON_DEMAND_MVR)
      this.mvr =
        worker.profile && worker.profile.checks
          ? validateMVRCheck(mvr_checks) || this.mvr
          : this.mvr;
    if (!_.isEmpty(mvr_checks) && typeof mvr_checks !== "string") {
      this.mvr.expiredLicenses = this.mvr.licenses ? this.expiredLicenses : [];
      this.mvr.invalidLicenses = this.mvr.licenses ? this.invalidLicenses : [];
      this.mvr.isMVRClear = this.mvr.status == "clear" ? true : false;
    }
    this.setNotes(worker.notes);
    this.onBoardingStatus === "verifying" && this.setIsPendingReview(true);
    if (worker.profile.person_report) {
      const addresses = this.getMergedAddresses(worker.profile);
      this.setCurrentAddress(worker.profile.current_address);
      this.setAddresses(addresses);
    }
  };

  resetChecks = async () => {
    const { worker } = this;
    if (!_.isEmpty(worker));
    this.setChecks(worker);

    if (worker.profile && worker.profile.checks) {
      this.setAssets(worker.profile.checks.assets);
      await this.setLocation(worker.profile.checks.location);
    }
  };

  setRecheck = async data => {
    const {
      assets,
      bankruptcies,
      criminal,
      county_criminal,
      judgements,
      liens,
      location,
      mvr,
      one_off_check_reports,
      rcmp_report,
      sex_offender,
      vehicles,
      watchlist,
      federal,
      ssn,
      notes,
      current_address,
      address_records,
      drug_testing
    } = data;
    await this.setLocation(location);
    this.setSSNChecks(data);
    this.setCurrentAddress(current_address);
    this.setAddresses(
      Array.isArray(address_records) ? address_records : current_address
    );
    this.criminalRecords = validateCheck(criminal);
    this.countyCriminalRecords = validateCheck(county_criminal);
    if (mvr !== ON_DEMAND_MVR) this.mvr = mvr ? mvr : this.mvr;
    this.sexOffenders = validateCheck(sex_offender);
    this.bankruptcies = validateCheck(bankruptcies);
    this.judgements = validateCheck(judgements);
    this.liens = validateCheck(liens);
    this.watchLists = validateCheck(watchlist);
    this.setOneOffChecks(one_off_check_reports);
    this.federalRecords = federal;
    this.canadianReport = rcmp_report;
    this.vehicleChecks = validateCheck(vehicles);
    this.setAssets(assets);
    this.setNotes(notes);
    this.setSSNTrace(ssn);
    this.selectedTabId && this.setIsPendingReview(false);
    if (this.doDrugTesting) {
      this.drugTestingResults = drug_testing;
    }
  };

  setFirstAndLastSeen = person_report => {
    if (!person_report) return;
    this.detailsFirstSeen =
      person_report.date_first_seen || this.detailsFirstSeen;
    this.detailsLastSeen = person_report.date_last_seen || this.detailsLastSeen;
    this.ssnFirstSeen =
      person_report.ssn_records && person_report.ssn_records[0]
        ? person_report.ssn_records[0].date_first_seen || this.ssnFirstSeen
        : this.ssnFirstSeen;
    this.ssnLastSeen =
      person_report.ssn_records && person_report.ssn_records[0]
        ? person_report.ssn_records[0].date_last_seen || this.ssnLastSeen
        : this.ssnLastSeen;
    this.addressFirstSeen =
      person_report.address_records && person_report.address_records[0]
        ? person_report.address_records[0].date_first_seen ||
          this.addressFirstSeen
        : this.addressFirstSeen;
    this.addressLastSeen =
      person_report.address_records && person_report.address_records[0]
        ? person_report.address_records[0].date_last_seen ||
          this.addressLastSeen
        : this.addressLastSeen;
  };

  setDetails = worker => {
    if (!worker) return;
    const { profile = {}, report_parameters = {} } = worker;

    this.driverLicense = profile.candidate_license_number || this.driverLicense;
    this.driverLicenseState =
      profile.candidate_license_state || this.driverLicenseState;

    const phone_number = isArray(profile.phone_number)
      ? profile.phone_number[0] || {}
      : {};
    this.phone = phone_number.number || this.phone;
    this.phoneVerification =
      validatePhoneVerification(phone_number.status) || this.phoneVerification;

    this.email = profile.email || this.email;
    this.dateOfBirth = profile.date_of_birth || this.dateOfBirth;
    this.userProvidedDOB = profile.user_provided_dob || this.userProvidedDOB;

    const signUpDate = profile.sign_up_date || null;
    this.signUpDate = signUpDate ? signUpDate.split(" ")[0] : this.signUpDate;

    this.ssn = profile.ssn ? profile.ssn.number || this.ssn : this.ssn;
    this.referenceId = profile.reference_id || this.referenceId;
    this.packageName =
      report_parameters && report_parameters.name
        ? report_parameters.name
        : this.packageName;
    this.report_parameters = report_parameters;
    this.turnId = worker.turn_id || this.turnId;
    this.initiated_date = worker.initiated_date || this.initiated_date;
    this.eta = worker.eta_date || this.eta;
  };

  setSSNTrace = ssn => {
    if (!ssn) return;
    this.is_random_ssn = ssn.is_random || this.is_random_ssn;
    this.ssnMatch = ssn.name_status || this.ssnMatch;
    this.deceasedDate = ssn.deceased_date || this.deceasedDate;
    this.is_deceased = ssn.is_deceased || this.is_deceased;
    this.ssnIssueDate = this.is_random_ssn
      ? "Not Available"
      : ssn.issue_date || this.ssnIssueDate;
    this.ssnIssueState = this.is_random_ssn
      ? "Not Available"
      : ssn.issue_state || this.ssnIssueState;
    this.ssnStatus = this.is_random_ssn
      ? "randomized"
      : ssn.status || this.ssnStatus;
    this.ssnVerified = ssn.status || this.ssnVerified;
  };
  setSSNChecks = checks => {
    this.ssnChecks = isObject(checks) ? checks : this.ssnChecks;
  };

  setDOBStatus = status => {
    if (typeof status !== "string") return;
    this.dobStatus = status;
  };

  translateStatus_extended(status, adverse_action) {
    if (status == "review__so" || status === "review__cm") {
      return "Compliance Review";
    }
    return translateStatus(status, adverse_action);
  }
  setWorkerSidebar = worker => {
    if (!worker || !worker.profile) return;
    this.firstName = worker.profile.first_name || this.firstName;
    this.lastName = worker.profile.last_name || this.lastName;
    this.status = worker.profile.status || this.status;
    this.plainOnBoardingStatus = worker.profile.partner_worker_status;
    this.onBoardingStatus =
      worker.profile && worker.profile.partner_worker_status
        ? this.translateStatus_extended(
            worker.profile.partner_worker_status,
            worker.adverse_action
          ) || this.onBoardingStatus
        : this.onBoardingStatus;
  };

  getMergedAddresses(profile) {
    const addresses = Object.assign(profile.person_report.address_records);
    if (profile.current_address) {
      addresses.shift();
      addresses.unshift(profile.current_address);
    }
    return addresses;
  }

  setAddresses = addresses => {
    this.addresses = Array.isArray(addresses) ? addresses : this.addresses;
  };

  setCurrentAddress = currentAddress => {
    this.currentAddress =
      _.isObject(currentAddress) && !Array.isArray(currentAddress)
        ? currentAddress
        : this.currentAddress;
  };

  setId = id => {
    this.id = id || this.id;
  };

  setLocation = async location => {
    if (location && location.lat && location.long) {
      const coords = location;
      const geocodeResponse = await geocode.get(coords.lat, coords.long);
      if (geocodeResponse.data.status === "OK") {
        const { address_components } = geocodeResponse.data.results[0];

        if (address_components) {
          let state = "";
          let city = "";
          address_components.forEach(address => {
            if (address.types.includes("administrative_area_level_1")) {
              state = address.long_name;
            }
            if (address.types.includes("administrative_area_level_2")) {
              city = address.long_name;
            }
          });
          this.location = {
            ...location,
            city,
            state
          };
        }
      }
    }
  };

  setRequestChargesUponRejection = setRequestCharges => {
    if (typeof setRequestCharges !== "boolean") return;
    this.setRequestCharges = setRequestCharges;
  };

  filterCountyCriminalData = records => {
    const filteredRecords = getFilteredRecords(records);
    if (!filteredRecords) return null;
    return filteredRecords;
  };

  filterFederalCriminalData = records => {
    const filteredRecords = getFilteredRecords(records);
    if (!filteredRecords) return null;
    return filteredRecords;
  };

  setNotes = notes => {
    this.notes = Array.isArray(notes) ? notes : notes;
  };

  setIsPendingReview = bool => {
    if (!_.isBoolean(bool)) return;
    this.isPendingReview = bool;
  };

  setIsPendingMVRReview = bool => {
    if (!_.isBoolean(bool)) return;
    this.isPendingMVRReview = bool;
  };

  setIsPendingAgeReview = bool => {
    if (!_.isBoolean(bool)) return;
    this.isPendingAgeReview = bool;
  };

  setHasMVRMonitoringOneOffChecks = oneOffCheckReports => {
    if (!_.isArray(oneOffCheckReports)) return;
    const mvrMonitoringOneOffChecks = oneOffCheckReports.filter(
      ({ name }) => name === MVR_MONITORING_ONE_OFF_CHECK
    );
    this.hasMVRMonitoringOneOffChecks = !_.isEmpty(mvrMonitoringOneOffChecks);
  };

  setIsProcessingMVR = bool => {
    if (!_.isBoolean(bool)) return;
    this.isProcessingMVR = bool;
  };

  setEnrollment = (enrollmentKey, isEnabled = false, status, updatedAt) =>
    (this[enrollmentKey] = { isEnabled, status, updatedAt });

  get workerIsPendingReview() {
    return (
      this.isPendingReview ||
      this.isProcessingMVR ||
      this.isPendingMVRReview ||
      this.isPendingAgeReview
    );
  }

  get ddppar() {
    return this.isDdppar;
  }

  setDdppar(ddppar) {
    this.isDdppar = ddppar;
  }

  set = async worker => {
    if (!worker) return;
    this.setChecks(worker);
    this.setDetails(worker);
    this.setWorkerSidebar(worker);
    this.setCriminalRecordsType(worker.profile.criminal_records_type);
    this.setSSNTrace(worker.profile.ssn);
    this.setZipcode(worker.profile.candidate_provided_zipcode);
    this.setManual(worker.profile.manual);
    this.setPDFURL(worker.profile.pdf_url);
    this.setHasCarInsurance(worker.profile.has_car_insurance);
    this.setRequestChargesUponRejection(worker.request_charges_upon_rejection);
    this.setChecksToRun(worker.pending_checks);
    this.setRechecks(worker.recheck);
    this.setCheckDate(worker.check_date);
    if (worker.rejection_details)
      this.rejectionDetails = worker.rejection_details;
    if (worker.ssn) this.setDOBStatus(worker.ssn.dob_status);
    if (worker.profile.check_status)
      this.checkStatus = worker.profile.check_status;
    if (worker.report_parameters)
      this.setShowSSNTrace(worker.report_parameters.do_ssn_trace);
    if (worker.profile.person_report) {
      const addresses = this.getMergedAddresses(worker.profile);
      this.setCurrentAddress(worker.profile.current_address);
      this.setAddresses(addresses);
      this.setFirstAndLastSeen(worker.profile.person_report);
    }
    if (worker.profile && worker.profile.checks) {
      this.setAssets(worker.profile.checks.assets);
      await this.setLocation(worker.profile.checks.location);
    }
    this.setIsProcessingMVR(worker.mvr_processing);
    this.setIsPendingReview(worker.ssn_review);
    this.setIsPendingMVRReview(worker.mvr_review);
    this.setIsPendingAgeReview(worker.age_review);
    this.setHasMVRMonitoringOneOffChecks(
      worker.profile.checks.one_off_check_reports
    );
    this.setDdppar(worker.profile.is_ddppar);
    this.plainOnBoardingStatus = worker.profile.partner_worker_status;
    this.setEnrollment(
      "criminalMonitoring",
      worker.continuous_monitoring.crim_enabled,
      worker.criminal_monitoring,
      worker.criminal_monitoring_last_update_date
    );
    this.setEnrollment(
      "drivingMonitoring",
      worker.continuous_monitoring.mvr_enabled,
      worker.mvr_monitoring,
      worker.mvr_monitoring_last_update_date
    );
  };
}

decorate(CurrentWorker, {
  loading: observable,
  selectedTabId: observable,
  criminalRecordsType: observable,
  driverLicense: observable,
  driverLicenseState: observable,
  phone: observable,
  phoneVerification: observable,
  email: observable,
  dateOfBirth: observable,
  userProvidedDOB: observable,
  signUpDate: observable,
  referenceId: observable,
  zipcode: observable,
  detailsFirstSeen: observable,
  detailsLastSeen: observable,
  ssnFirstSeen: observable,
  ssnLastSeen: observable,
  addressFirstSeen: observable,
  addressLastSeen: observable,
  ssn: observable,
  ssnStatus: observable,
  ssnVerified: observable,
  ssnMatch: observable,
  ssnIssueDate: observable,
  ssnIssueState: observable,
  deceasedDate: observable,
  is_deceased: observable,
  dobStatus: observable,
  is_random_ssn: observable,
  geocodeData: observable,
  vehicles: observable,
  properties: observable,
  ssnChecks: observable,
  doDrugTesting: observable,
  drugTestingResults: observable,
  sexOffenders: observable,
  vehicleChecks: observable,
  criminalRecords: observable,
  countyCriminalRecords: observable,
  oneOffChecks: observable,
  canadianReport: observable,
  bankruptcies: observable,
  judgments: observable,
  liens: observable,
  watchLists: observable,
  mvr: observable,
  addresses: observable,
  currentAddress: observable,
  showAllAddresses: observable,
  location: observable,
  firstName: observable,
  lastName: observable,
  status: observable,
  onBoardingStatus: observable,
  plainOnBoardingStatus: observable,
  checkStatus: observable,
  criminalMonitoring: observable,
  drivingMonitoring: observable,
  id: observable,
  manual: observable,
  pdfURL: observable,
  showSSNTrace: observable,
  showCounty: observable,
  setRequestCharges: observable,
  hasCarInsurance: observable,
  isPendingReview: observable,
  isPendingMVRReview: observable,
  isProcessingMVR: observable,
  isPendingAgeReview: observable,
  hasMVRMonitoringOneOffChecks: observable,
  checksToRun: observable,
  recheck: observable,
  worker: observable,
  loadingRecheck: observable,
  readOnly: observable,
  checkDate: observable,
  notes: observable,
  federalRecords: observable,
  mvr_processing: observable,
  ssn_review: observable,
  mvr_review: observable,
  age_review: observable,
  isDdppar: observable,
  eta: observable,
  initiated_date: observable,
  ddppar: computed,
  fullName: computed,
  currentState: computed,
  providedState: computed,
  expiredLicenses: computed,
  invalidLicenses: computed,
  isMVRClear: computed,
  driverLicenseStatus: computed,
  mortality: computed,
  countyCriminalData: computed,
  federalCriminalData: computed,
  candidate_provided_ssn: computed,
  details: computed,
  setZipcode: action,
  setGeocodeData: action,
  setNotes: action,
  stateAbbr: computed,
  criminalData: computed,
  watchlistData: computed,
  propertyData: computed,
  vehicleData: computed,
  preAdverseJurisdiction: computed,
  checks: computed,
  setLoading: action,
  setSelectedTabId: action,
  setManual: action,
  setPDFURL: action,
  setCriminalRecordsType: action,
  setAssets: action,
  setChecks: action,
  setFirstAndLastSeen: action,
  setDetails: action,
  setSSNTrace: action,
  setSSNChecks: action,
  setDOBStatus: action,
  setWorkerSidebar: action,
  setAddresses: action,
  setCurrentAddress: action,
  setShowSSNTrace: action,
  setShowAllAddresses: action,
  setId: action,
  setLocation: action,
  set: action,
  filterCountyCriminalData: action,
  filterFederalCriminalData: action,
  setHasCarInsurance: action,
  setChecksToRun: action,
  setRechecks: action,
  setRecheck: action,
  setLoadingRecheck: action,
  setReadOnly: action,
  resetChecks: action,
  setIsPendingReview: action,
  setIsPendingMVRReview: action,
  setIsProcessingMVR: action,
  setIsPendingAgeReview: action,
  setHasMVRMonitoringOneOffChecks: action,
  shouldShowCheck: action,
  setCheckDate: action,
  setDdppar: action,
  setEnrollment: action
});

const currentWorker = new CurrentWorker();

export default currentWorker;
export { CurrentWorker };
